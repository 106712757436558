<template>
  <v-container class="px-0">
    <v-form ref="ArticleForm" name="ArticleForm">
      <BaseScreenHeader title="Add Article" screenInfo="ART-001" />

      <v-card-text class="pb-0 silver--text">
        <v-row dense>
          <v-col cols="12" md="3">
            <v-sheet color="transparent" class="pa-2">
              <v-switch
                dark
                ref="public"
                false-value="No"
                true-value="Yes"
                v-model="is_published"
                color="success"
              >
                <template v-slot:label>
                  Published?
                </template>
              </v-switch>
            </v-sheet>
          </v-col>
          <v-col cols="12" md="9">
            <v-row dense>
              <v-col cols="12">
                <v-textarea
                  dark
                  filled
                  v-model="title"
                  ref="title"
                  :error-messages="tittleErrors"
                  :counter="100"
                  :rows="2"
                  label="Title"
                  prepend-inner-icon="mdi-subtitles"
                  @input="$v.title.$touch()"
                  @blur="
                    $v.title.$touch()
                    checkpermalink()
                  "
                />
              </v-col>
              <v-col cols="12" dense>
                <v-card-text
                  class="py-0 mt-n5 mb-2 pl-4 caption progressActive--text"
                >
                  <span class="silver--text">Permalink: </span>
                  {{ permalink }}
                </v-card-text>
                <v-card-text v-if="!permalinkUnique" class="py-0 ml-4 caption">
                  <span class="orange--text">
                    permalink is not unique, please make sure an article with
                    the same name does not exist. To continue, change the
                    article title.
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dark
                  filled
                  v-model="articleType"
                  :items="articleTypes"
                  label="Article Type"
                  persistent-hint
                  item-text="title"
                  item-value="value"
                  prepend-inner-icon="mdi-chevron-right-box"
                  class="success--text"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12">
            <v-text-field
              dark
              filled
              v-model="image"
              ref="image"
              :counter="500"
              label="Article Image Url"
              prepend-inner-icon="mdi-image-area"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              dark
              filled
              v-model="intro"
              ref="intro"
              :error-messages="introErrors"
              :counter="250"
              :rows="3"
              label="Intro"
              prepend-inner-icon="mdi-text"
              @input="$v.intro.$touch()"
              @blur="$v.intro.$touch()"
            />
          </v-col>
          <v-col cols="12">
            <h5 class="silver--text pb-1 pl-1">Article Content</h5>
            <tiptap-vuetify
              v-model="content"
              :extensions="extensions"
              placeholder="Write your article content here.."
            />
          </v-col>
        </v-row>
        <v-row dense class="mt-2">
          <v-col cols="12">
            <v-autocomplete
              dark
              clearable
              ref="muscles"
              filled
              v-model="muscles"
              :items="musclesList"
              label="Tag Muscles"
              multiple
              chips
              hint="What are the muscles that should be tagged?"
              persistent-hint
              item-text="display"
              item-value="id"
              item-color="success"
              prepend-inner-icon="mdi-arm-flex"
              color="success"
              :menu-props="{
                closeOnClick: true,
                maxHeight: 250,
                transition: 'fab-transition'
              }"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pl-0">
        <v-spacer />
        <BaseActionButton
          :disabled="$v.$anyError || !permalinkUnique"
          label="Add Article"
          icon="mdi-plus-circle-outline"
          large
          dark
          plain
          class="paper--text"
          @clickedThis="saveArticle()"
        />
      </v-card-actions>
    </v-form>
  </v-container>
</template>
<script>
import {
  TiptapVuetify,
  Image,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import articleTypes from '@/json/articletypes.json'

export default {
  components: { TiptapVuetify },
  mixins: [validationMixin, util],
  data: () => ({
    articleTypes: articleTypes,
    permalinkUnique: true,
    theforces: [],
    mechanics: [],
    utility: [],
    mechanicsType: '',
    utilityType: '',
    theforceType: '',
    title: '',
    image: '',
    articleType: '',
    intro: '',
    content: '',
    is_published: 'Yes',
    muscles: [],
    equipment: [],
    musclesList: [],
    equipmentList: [],
    articleTypeList: [],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      Image,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ]
  }),
  beforeMount() {
    this.loadMusclesDefinition()
  },
  mounted() {
    this.articleType = this.articleTypes[0]
  },
  validations: {
    title: {
      required
    },
    intro: {
      required
    }
  },
  computed: {
    permalink() {
      return this.generateCode(this.title)
    },
    tittleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.required && errors.push('Article title is required.')
      return errors
    },
    introErrors() {
      const errors = []
      if (!this.$v.intro.$dirty) return errors
      !this.$v.intro.required && errors.push('intro is required.')
      return errors
    },
    ...appConfig
  },
  methods: {
    tileColor(selected) {
      return selected ? 'charcoal charcoalTileMenu' : 'charcoal'
    },
    textColor(selected) {
      return selected ? 'paper--text' : 'silver--text'
    },
    iconColor(selected) {
      return selected ? 'paper' : 'silver'
    },
    checkpermalink() {
      {
        if (!this.permalink?.length > 0) return
        return axios
          .get(this.baseURL + '/article/checkpermalink/' + this.permalink, {})
          .then(response => {
            if (response.status == 200) {
              this.permalinkUnique = response.data.data.is_unique == 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveArticle() {
      window.scrollTo(0, 0)
      return axios
        .post(this.baseURL + '/article', {
          title: this.title,
          articleType: this.articleType,
          intro: this.intro,
          content: this.content,
          category: this.category,
          is_published: this.is_published,
          permalink: this.permalink,
          muscles: this.muscles
        })
        .then(response => {
          if (response.status == 200) {
            this.oldname = this.title
            this.$refs.ArticleForm.reset()
            this.title = ''
            this.intro = ''
            this.content = ''
            this.articleType = ''

            this.toast('Article ' + this.title + ' was succesfully added.')
            this.$router.push('/articles')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMusclesDefinition() {
      {
        return axios
          .get(this.baseURL + '/util/muscles/all', {})
          .then(response => {
            if (response.status == 200) {
              this.musclesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadEquipment() {
      {
        return axios
          .get(this.baseURL + '/util/equipment/all', {})
          .then(response => {
            if (response.status == 200) {
              this.equipmentList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
<style>
.tiptap-vuetify-editor__content .ProseMirror {
  min-height: 250px; /* Or whatever height you prefer */
}
</style>
