<template>
  <v-container>
    <BaseScreenHeader
      title="View Article"
      screenInfo="ART-003"
      width="90%"
      :showBack="true"
      :showNav="true"
      :refresh="false"
      @refresh="refresh"
    />

    <BaseFreeTier v-if="loaded && loadedList.length > 5" />

    <v-card-text class="px-1 py-0 mt-4 mb-0">
      <v-text-field
        background-color="charcoal lighten-1"
        class="text-h5"
        height="80"
        solo
        dark
        clearable
        searchCounter
        label="Search Articles"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        single-line
        hide-details
        autofocus
        @focus="$event.target.select()"
        @input="searchArticles"
      />
    </v-card-text>
    <BaseLoading :loaded="loaded" label="Loading" />
    <v-sheet v-if="articlesList.length > 0" class="charcoal">
      <v-card
        rounded
        class="pa-2 charcoal mt-2 rounded-lg rounded-t-0 charcoalTile"
        v-for="item in articlesList"
        :key="item.measurementid"
      >
        <v-card-actions class="caption pb-2 px-0 silver--text ">
          {{ prettyDate(item.date_published) }}
          <v-spacer />

          <BaseTag
            icon="mdi-focus-field"
            :label="item.category"
            tagsize="medium"
            color="transparent charcoalTileMenu pinkAccent--text"
          />
        </v-card-actions>
        <v-card-text :class="textSizeSmall + ' progressActive--text pa-0'">
          {{ item.title }}
        </v-card-text>
        <v-container fluid fill-height class="charcoalTileMenu">
          <v-layout align-center justify-center>
            <v-flex xs12 md8>
              <v-img
                :src="item.image_url"
                contain
                max-width="100%"
                max-height="100%"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-text :class="textSizeSmall + ' progressActive--text px-0'">
          {{ item.intro }}
        </v-card-text>
        <v-card class="paper pa-2">
          <v-card-text
            class="excerpt paper charcoal--text px-0"
            v-if="!item.showFullContent"
          >
            <span v-html="getExcerpt(item.content)" :class="textSizeXSmall">
            </span>
          </v-card-text>

          <v-card-text v-else class="excerpt paper charcoal--text px-0">
            <span v-html="item.content" :class="textSizeXSmall"> </span>
          </v-card-text>
          <v-card-actions class="pb-0 pt-4 px-0">
            <v-spacer />
          </v-card-actions>
          <v-card-actions :class="textSizeXSmall + ' pl-0 py-2'">
            <BaseActionButton
              v-if="xvy"
              :large="!isPhone"
              icon="mdi-trash-can-outline"
              text
              color="silver"
              class="ml-n2"
              small
              label="Delete"
              @clickedThis="showRowDialog(item)"
            />
            <v-spacer />
            <v-btn
              :style="charcoalTile"
              color="charcoal"
              class="pa-0"
              text
              @click="togglecontent(item)"
            >
              {{ !item.showFullContent ? 'Full Article' : 'Excerpt' }}
              <v-icon>
                {{
                  item.showFullContent ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card-text class=" silver--text pa-0 my-2 ml-4 text-center">
          <span v-for="(child, index) in item.muscles" :key="index">
            <BaseTag
              icon="mdi-focus-field"
              :label="child.name"
              :tagsize="isPhone ? 'small' : 'medium'"
              color="transparent charcoalTileMenu paper--text"
              @click="openContent(child)"
            />
          </span>
        </v-card-text>
      </v-card>
    </v-sheet>

    <v-dialog
      v-model="showDeleteDialog"
      :fullscreen="isPhone"
      persistent
      max-width="800"
      overlay-color="#1f2c4c"
      overlay-opacity=".97"
    >
      <v-card
        v-if="selectedArticle != null"
        class="d-flex flex-column noscroll charcoal silver--text"
      >
        <BaseCloseDialogHeader
          heading="Delete Article?"
          @cancel="showDeleteDialog = false"
        />
        <v-card-text class="px-0">
          <v-card-text class="progressActive--text">
            Your {{ selectedArticle.title }}
          </v-card-text>
          <v-card-subtitle :class="textSizeXSmall + ' silver--text pt-0 pb-4'">
            Published on {{ prettyDate(selectedArticle.date_published) }}.<br />
          </v-card-subtitle>
          <v-spacer />
          <v-card-text class="paper--text">
            Review the information above. Click the
            <span class="progressActive--text">Delete</span> button to remove
            the article.
          </v-card-text>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="pl-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            color="silver"
            plain
            :large="!isPhone"
            @clickedThis="showDeleteDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            label="Delete"
            text
            class="paper--text"
            :large="!isPhone"
            icon="mdi-trash-can-outline"
            @clickedThis="deleteArticle()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'
import { appConfig } from '@/store/helpers.js'
export default {
  components: {},
  name: 'ListArticles',
  mixins: [validationMixin, util],
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      switchNum: 0,
      loaded: false,
      isActive: false,
      showAddArticle: false,
      showDeleteDialog: false,
      showHelpText: false,
      isUserScrolling: false,
      isNow: true,
      selectedArticle: {},
      metric: 0,
      imperial: 0,
      feet: 0,
      inches: 0,
      measured: '',
      search: '',
      articlesList: [],
      loadedList: [],
      datetime: null,
      selectedDateTime: null,
      debouncedScroll: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false,
      reload: false
    }
  },
  computed: {
    ...appConfig
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  props: {
    articleid: {
      type: String
    }
  },
  methods: {
    goTo(path) {
      if (this.$route.path != path) {
        this.$router.push(path)
      } else this.$router.go()
    },
    openContent(item) {
      this.goTo('/search/bymuscle/' + item.muscleid)
    },
    getExcerpt(content) {
      // Extract the first 150 words from the content parameter
      const words = content.split(' ')
      return words.slice(0, 150).join(' ')
    },
    togglecontent(item) {
      item.showFullContent = !item.showFullContent
    },
    setResults(value) {
      this.articlesList = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    doneAdding() {
      this.showAddArticle = false
      this.currentPage = 0
      this.reload = true
      this.loadPage()
    },

    showRowDialog(value) {
      this.selectedArticle = value
      this.showDeleteDialog = true
    },
    refresh() {
      this.reload = true
      this.currentPage = 0
      this.loadPage()
    },
    loadPage() {
      this.loaded = false

      let url = this.baseURL + '/articles/view/' + this.articleid
      return axios.get(url, {}).then(response => {
        if (response.status == 200) {
          response.data.data.forEach(element => {
            element.showFullContent = true
            element.muscles = JSON.parse(element.article_muscles)
          })

       this.articlesList = response.data.data

          this.loaded = true
          this.reload = false
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },

    deleteArticle() {
      return axios
        .delete(
          this.baseURL +
            '/measurement/' +
            this.selectedArticle.measurementid +
            ':' +
            this.selectedArticle.measurementtypeid
        )
        .then(response => {
          if (response.status == 200) {
            this.selectedArticle = {}
            this.showDeleteDialog = false
            this.reload = true
            this.loadPage()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Measurement deleted succesfully',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    searchArticles() {
      {
        this.reload = true
        this.loadPage()
      }
    }
  }
}
</script>

<style>
/* Add CSS transitions for fading effect */
/* Add CSS transitions for fading effect */
.excerpt {
  background: linear-gradient(transparent 0%, rgba(255, 255, 255, 1) 70%);
  -webkit-background-clip: text;
  background-clip: text;
  color: #efefef;
  display: inline;
  transition: background 0.5s ease-in-out;
  cursor: pointer;
}

.excerpt:hover {
  background: linear-gradient(transparent 0%, rgba(255, 255, 255, 1) 0%);
  color: #fafafa; /* Set the text color when hovered */
}
</style>
